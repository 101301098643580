import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Logo } from "../svg-modules/index";
import ProjectCarousel from "../modules/project_carousel/index";
import OnGoingProjectCarousel from "../modules/onGoingProj_carousel/index";
import { Helmet } from "react-helmet";
const Page = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [carouselSlide, setCarouselSlide] = useState("slide-1");
  const [onGoingCarouselSlide, setOnGoingCarouselSlide] = useState("slides-1");
  //   -------For OnGoingProjects ------------
  //   const [onGoingProjSelected, setOngoingProject] = useState(6);
  //   const [selectedOnGoingProjImg, setSelectedOnGoingProjImg] = useState("");
  // ----------------------------

  useEffect(() => {
    async function fetchDetails() {
      Promise.all([props.fetchAllProjects(), props.fetchOnGoingProject()]);
    }
    !props.projects && fetchDetails();
  }, []);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>NEW COSMO BUILDERS, INC. - LOS ANGELES CONSTRUCTION COMPANY</title>
        <meta
          name="keywords"
          content="Los Angeles Construction Company, building contractors near me, construction companies near me, remodeling companies near me, top construction companies"
        />
        <meta name="description" content="New Cosomo Builders INC. has established itself as an architecture with global perspective and breadth of experience in California.  We believe innovative and effective design and architecture begin with your own philosophy and a collaborative approach."/>
      </Helmet>
      <Container className="landing__page__container" id="mobile-landing__page__container">
        <Row className="banner__wrapper">
          <Col className="banner__logo">
            {Logo("white", "", "landing__banner__logo")}
          </Col>
          <Col className="banner__scripts" id="mobile-banner__scripts">
            <div class="banner__scripts__title">
              <span class="banner__scripts__block"></span>
              <h1 className="scripts__title">
                New Cosmo Builders, INC
                <span className="banner__scripts__dot"></span>
              </h1>
            </div>

            <div class="banner__scripts__address" id="mobile-banner__scripts__address">
              <div class="banner__scripts__block"></div>
              <p className="scripts__address">
                1726 W. Venice Blvd. Los Angeles, CA 90006
              </p>
            </div>
          </Col>
        </Row>
        <Row className="aboutus__wrapper" id="mobile-aboutus__wrapper">
          <Col className="aboutus__scripts" id="mobile-aboutus__scripts">
            <h3>
              The environment of your business constructs the image of who you
              are...
            </h3>
            <p>
              At New Cosmo Builders, we believe innovative and effective
              design/architecture begins with your own philosophy and approach
            </p>
            <Link to="/about-us">
              <button className="aboutus__button">Learn More</button>
            </Link>
          </Col>
          <Col>{/* Insert Image */}</Col>
        </Row>

        <Row className="projects__wrapper" id="mobile-projects__wrapper">
          <Col className="projects__contents">
            <Col className="projects__scripts">
              <h2>Ongoing Project</h2>
            </Col>
          </Col>
        </Row>
        {props.onGoingProject && (
          <OnGoingProjectCarousel
            slide={onGoingCarouselSlide}
            projects={props.onGoingProject}
            setCarouselSlide={setOnGoingCarouselSlide}
          />
        )}

        <Row className="info__wrapper" id="mobile-info__wrapper">
          <div className="info__header">
            <h1>Who We Are</h1>
            <div className="info__text">
              <p>
                Who we are is not so different from what we do and how. We are a
                group of developers, constructors, architectures, designers and
                planners, each with a relevant specialization and dedication to
                posing imaginative design solutions.
              </p>
            </div>
          </div>

          <div className="info__content__wrapper">
            <div className="info__content__image">
              <img src="https://images.unsplash.com/photo-1612935089040-89195ef54677?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80" />
            </div>
            <div className="info__content__text-box">
              <span className="info__content_text-bold">What We Do</span>
              <span className="info__content_text">
                With over decade of years of experience, we bring top quality
                construction services throughout all types of construction, big
                to small, from any small residential remodeling to commercial
                mall construction.
              </span>
            </div>
          </div>
          <div className="info__content__wrapper--reversed">
            <div className="info__content__image">
              <img src="https://images.unsplash.com/photo-1589216767742-2835bbe81a51?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80" />
            </div>
            <div className="info__content__text-box">
              <span className="info__content_text-bold">Our Mission</span>
              <span className="info__content_text">
                Our Team is aim to deliver complete client satisfaction with
                competitive price, reliable timescale, and the output with
                finest quality throughout entire California and even more.
              </span>
            </div>
          </div>
        </Row>
        <Row className="projects__wrapper">
          <Col className="projects__contents">
            <Col className="projects__scripts">
              <h2>Our Projects</h2>
            </Col>
          </Col>
        </Row>
        {props.projects && (
          <ProjectCarousel
            slide={carouselSlide}
            projects={props.projects.filter((project) => project.slidePic)}
            setCarouselSlide={setCarouselSlide}
          />
        )}
        <Col className="projects__button">
          <Link to="/projects">
            <button className="projects__button__style" type="button">
              See All Projects
            </button>
          </Link>
        </Col>
        <Row
          className="contact__wrapper"
          style={{
            backgroundImage:
              "url(https://media.gettyimages.com/photos/model-house-and-blueprint-picture-id471134551?k=6&m=471134551&s=612x612&w=0&h=RD_GStCPAqQDRQcWrgs2bF-FAcoBgnF7hBZPJUS_ZkU=)",
          }}
        >
          <div className="contact__scripts">
            <h3>Get in Touch</h3>
            <p>We'd love to hear from you and help you get started!</p>
          </div>
          <div>
            <Link to="/contact-us">
              <button className="contact__button">Contact Us</button>
            </Link>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Page;
