import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./styles.scss";
import { Logo } from "../../svg-modules/index";

const Navbar = () => {
  return (
    <div className="ltwim__navbar__wrapper">
      <div className="navbar__wrapper__container">
        <div className="navbar__header" id="mobile-navbar__header">
          <Link exact to="/" className="nav__home__text">
            <span>NEW COSMO BUILDERS</span>
          </Link>
          <Link exact to="/" className="nav__home__logo">
            {Logo("black", "", "nav__logo")}
          </Link>
        </div>
        <div
          className="navbar__links__container"
          id="mobile-navbar__links__container"
        >
          <NavLink exact to="/">
            Home
          </NavLink>
          <NavLink exact to="/projects">
            Portfolios
          </NavLink>
          <NavLink exact to="/services">
            Services
          </NavLink>
          <NavLink exact to="/about-us">
            About Us
          </NavLink>
        </div>
        <div
          className="navbar__contactUs__container"
          id="mobile-navbar__contactUs__container"
        >
          <div className="navbar__contactUs__links">
            <NavLink to="/contact-us">Contact Us</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
