import { connect } from "react-redux";
import Projects from "./index";
import {
  getAllProjects,
  getProject,
  receiveFilteredProjects,
} from "../../actions/entities/projects_actions";

const mapStateToProps = (state) => {
  return {
    projects: state.Entities.Projects.projects,
    filteredProjects: state.Entities.Projects.filteredProjects
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllProjects: () => dispatch(getAllProjects()),
    fetchFilteredProjects: (projects) => dispatch(receiveFilteredProjects(projects))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
