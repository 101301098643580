
import { merge } from "lodash";
import {RECEIVE_ALL_PROJECTS, RECEIVE_PROJECT, RECEIVE_ON_GOING_PROJECTS, RECEIVE_FILTERED_PROJECTS} from '../../constants/index'
export default (state = {}, action) => {
  Object.freeze(state);
  let newState = merge({}, state);
  switch (action.type) {
    case RECEIVE_ALL_PROJECTS:
      newState['projects'] = action.projects
      return newState
    case RECEIVE_PROJECT:
      newState['project'] =action.project
      return newState
    case RECEIVE_ON_GOING_PROJECTS:
       newState["onGoingProjects"] = action.payload.onGoingProjects
       return newState
    case RECEIVE_FILTERED_PROJECTS:
      newState["filteredProjects"] = action.filteredProjects
      return newState
    default:
      return state;
  }
};

