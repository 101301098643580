import axios from "axios";

export const fetchAllProjects = () => {
    return axios.patch("/api/projects")
}

export const fetchProject = (id) => {
    return axios.patch(`/api/projects/project/${id}`)
}
export const fetchOnGoingProject = () => {
    return axios.patch("/api/projects/on_going_project")
}