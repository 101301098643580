import React, { useState } from "react";
import "./styles.scss";
import { ContactUs_Address } from "../modules/contact-us__svg/Address";
import { ContactUs_Email } from "../modules/contact-us__svg/Email";
import { ContactUs_Phone } from "../modules/contact-us__svg/Phone";
import { useEffect } from "react";
import { addContactUsEmail } from "../../util/api_utils/email_api_util";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  function handleChange(e) {
    e.preventDefault();
    switch (e.target.name) {
      case "name":
        return setName(e.target.value);
      case "email":
        return setEmail(e.target.value);
      case "subject":
        return setSubject(e.target.value);
      case "message":
        return setMessage(e.target.value);
      default:
        return;
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    let form = {
      name,
      email,
      subject,
      message,
    };
    addContactUsEmail(form)
      .then(() =>
        swal({
          title: "Thank You For Your Time.",
          text: "Our team member will reach you as soon as possible.",
          icon: "success",
          button: "close",
        })
      )
      .catch((err) =>
        swal({
          title: `${err.response.data.errors}`,
          text: "Please try again later.",
          button: "close",
          icon: "warning",
        })
      );

    console.log(form);
  }
  // const cardInfo = [
  //   {
  //     name: "Amsterdam",
  //     address: "3rd Floor 66 Queen Street BROOK VALE NSW 2100",
  //     email: "amsterdam@email.com​",
  //     phone: "+51853 458 243",
  //     photo: "/img/amsterdam.jpg",
  //   },
  //   {
  //     name: "Chicago",
  //     address: "4th Floor 3918 Randall Moanalua, HI 96819",
  //     email: "chicago@email.com​",
  //     phone: "+51853 458 243",
  //     photo: "/img/chicago.jpg",
  //   },
  //   {
  //     name: "Singapore",
  //     address: "1st Floor 66 King Street BROOK VALE SP 103",
  //     email: "singapore@email.com​​",
  //     phone: "+51853 458 243",
  //     photo: "/img/singapore.jpg",
  //   },
  // ];

  const cardblock = (name, address, email, phone, photo) => {
    return (
      <div className="Contact-us__card-block__wrapper__innerwrap__block">
        <div
          className="Contact-us__card-block__wrapper__innerwrap__block__pic"
          style={{
            backgroundImage: `url(${photo})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="Contact-us__card-block__wrapper__innerwrap__block__info">
          <div className="Contact-us__card-block__wrapper__innerwrap__block__info__bold-text">
            {name}
          </div>
          <div className="Contact-us__card-block__wrapper__innerwrap__block__info__light-text">
            {ContactUs_Address("black")}
            <div className="Contact-us__card-block__wrapper__innerwrap__block__info__space" />
            {address}
          </div>
          <div className="Contact-us__card-block__wrapper__innerwrap__block__info__light-text">
            {ContactUs_Email("black")}
            <div className="Contact-us__card-block__wrapper__innerwrap__block__info__space" />
            {email}
          </div>
          <div className="Contact-us__card-block__wrapper__innerwrap__block__info__phone">
            {ContactUs_Phone("phone")}
            <div className="Contact-us__card-block__wrapper__innerwrap__block__info__space" />
            {phone}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="Contact-us__wrapper">
      <Helmet>
        <title>Contact Us | New Cosmo Builders, INC.</title>
        <meta
          name="keywords"
          content="best local construction companies near me Los Angeles, best building construction companies near me Los Angeles, remodeling construction companies los angeles, best general contractors near me los angeles, best remodeling contractors near me los angeles."
        />
        <meta
          name="description"
          content="We are here to help you achieve your dream home as if it is our own."
        />
      </Helmet>
      <div className="Contact-us__form__wrapper">
        <div
          className="Contact-us__form__wrapper__fillout-box"
          id="mobile-Contact-us__form__wrapper__fillout-box"
        >
          <div className="Contact-us__form__wrapper__fillout-box__textbox">
            <div className="Contact-us__form__wrapper__fillout-box-textbox">
              <div className="Contact-us__form__wrapper__fillout-box__textbox__bold-text">
                We're here to help
              </div>
              <div className="Contact-us__form__wrapper__fillout-box__textbox__light-text">
                Fill out the form or call 213.270.4630
              </div>
              <input
                className="Contact-us__form__wrapper__fillout-box__textbox__typing-1"
                style={
                  name
                    ? { borderBottom: "none" }
                    : { borderBottom: "1px solid #9c1919" }
                }
                placeholder="Name"
                name="name"
                value={name}
                onChange={handleChange}
              ></input>
              {name && <div className="borderLine"></div>}
              <input
                className="Contact-us__form__wrapper__fillout-box__textbox__typing-1"
                placeholder="Email"
                style={
                  email
                    ? { borderBottom: "none" }
                    : { borderBottom: "1px solid #9c1919" }
                }
                name="email"
                value={email}
                onChange={handleChange}
              ></input>
              {email && <div className="borderLine"></div>}
              <input
                className="Contact-us__form__wrapper__fillout-box__textbox__typing-1"
                placeholder="Subject"
                style={
                  subject
                    ? { borderBottom: "none" }
                    : { borderBottom: "1px solid #9c1919" }
                }
                name="subject"
                value={subject}
                onChange={handleChange}
              ></input>
              {subject && <div className="borderLine"></div>}
              <textarea
                className="Contact-us__form__wrapper__fillout-box__textbox__typing-2"
                style={
                  message
                    ? { borderBottom: "none" }
                    : { borderBottom: "1px solid #9c1919" }
                }
                placeholder="Message"
                name="message"
                value={message}
                onChange={handleChange}
              ></textarea>
              {message && <div className="borderLine"></div>}
              <button
                onClick={onSubmit}
                disabled={!name || !email || !subject || !message}
                className="Contact-us__form__wrapper__fillout-box__textbox__Submit-button"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div
          className="Contact-us__form__wrapper__picture-box"
          style={{
            backgroundImage: `url("/img/contactus.jpeg")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      {/* <div className="Contact-us__card-block__wrapper">
        <div className="Contact-us__card-block__wrapper__innerwrap">
          {cardInfo.map((card) =>
            cardblock(
              card.name,
              card.address,
              card.email,
              card.phone,
              card.photo
            )
          )}
        </div>
      </div> */}
    </div>
  );
};

export default ContactUs;
