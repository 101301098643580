import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

const OnGoingProjectCarousel = (props) => {
  let projects = props.projects.pics.slice(0, 6);
  let mapRadioCheckBox = (i) => {
    let slide = `slides-${i + 1}`;
    return (
      <input
        type="radio"
        name="slide"
        value={slide}
        checked={props.slide === slide}
      />
    );
  };

  let mapCarouselSlide = (project) => {
    return (
      <li className="carousel__slide">
        <figure className="carousel__slide__figure">
          <div className="carousel__slide__image">
            <img src={project.slidePic ? project.slidePic : project} alt="" />
          </div>
        </figure>
      </li>
    );
  };

  let mapCarouselThumbNails = (project, index) => {
    return (
      <li
        onClick={(e) => {
          e.preventDefault();
          console.log(project);
          props.setCarouselSlide(`slides-${index + 1}`);
        }}
      >
        <label for={`slides-${index + 1}`}>
          <img src={project.slidePic ? project.slidePic : project} alt="" />
        </label>
      </li>
    );
  };
  return (
    <div className="projects__carousel__wrapper">
      <div className="projects__carousel__container">
        <div className="projects__carousel reversed" id="mobile-projects__carousel--reversed">
        <div className="carousel__slide__figCaption">
        <span className="carousel__slide__title">{props.projects.name}</span>
        <span>{props.projects.location}</span>
        {/* <span>${project.cost}</span> */}
        <span>{props.projects.category}</span>
        <Link to={`/projects/${props.projects._id}`}>
          <button className="aboutus__button">Learn More</button>
        </Link>
      </div>
          {[...Array(6).keys()].map((idx) => mapRadioCheckBox(idx))}
          <ul className="carousel__slides">
            {projects.map((project) => mapCarouselSlide(project))}
          </ul>
          <ul className="carousel__thumbnails">
            {projects.map((project, index) =>
              mapCarouselThumbNails(project, index)
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OnGoingProjectCarousel;
