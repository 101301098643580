import React, { useEffect, useState } from "react";
import "./styles.scss";
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet"
const Projects = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [filter, setFilter] = useState("All");
  useEffect(() => {
    async function fetchDetails() {
      Promise.all([props.fetchAllProjects()]);
    }
    !props.projects && fetchDetails();

    return () => {
      props.fetchFilteredProjects();
    };
  }, []);
  const filteredButton = (name) => {
    return (
      <div className="projects__button__cont">
      <button style={{backgroundColor: name === filter && "black", color:name === filter && "white" }} onClick={filterProjects} name={name} value={name}>
        {name}
      </button>
      </div>
    );
  };
  const filterProjects = (e) => {
    e.preventDefault();
    let filteredProjects = [];
    switch (e.target.value) {
      case "Residential":
        setFilter("Residential")
        filteredProjects = props.projects.filter(
          (project) => project.category === "Residential"
        );
        props.fetchFilteredProjects(filteredProjects);
        break;
      case "Commercial":
        setFilter("Commercial")
        filteredProjects = props.projects.filter(
          (project) => project.category === "Commercial"
        );
        props.fetchFilteredProjects(filteredProjects);
        break;
      case "Restaurant":
        setFilter("Restaurant")
        filteredProjects = props.projects.filter(
          (project) => project.category === "Restaurant"
        );
        props.fetchFilteredProjects(filteredProjects);
        break;
      case "All":
        setFilter("All")
        props.fetchFilteredProjects();
        break;
      default:
        return;
    }
  };
  const cardblock = (project) => {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          props.history.push(`/projects/${project._id}`);
        }}
        className="projects__card-cont__innerbox__card"
      >
        <div
          className="projects__card-cont__innerbox__card__pic"
          style={{
            backgroundImage: `url(${project.mainPic})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="projects__card-cont__innerbox__card__over-lay">
            {project.name.includes("<")
              ? project.name.substring(0, project.name.indexOf("<"))
              : project.name}
          </div>
        </div>
        <div className="projects__card-cont__innerbox__card__info">
          <div className="projects__card-cont__innerbox__card__info__title">
            {project.location}
          </div>
          <div className="projects__card-cont__innerbox__card__info__text">
            {project.size && <span>Size:{project.size}</span>}
            {project.cost && <span>Value: ${project.cost}</span>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="projects-wrapper">
      <Helmet>
        <title>Our Works | New Comso Builders, INC.</title>
        <meta name="description" content="At New Cosmo Builders, INC., all of our works are established with finest quality, competitive price, and realiable timescale."/>
        <meta name="keywords" content="best retail remodeling companies los angeles, best commercial construction companies los angeles, best quality construction companies, commercial general contractors near me los angeles, best local contractors near me."/>
      </Helmet>
      {/* lander banner */}
      <div className="projects__landing-cont">
        <div className="projects__landing-cont__inner-box">
          <div className="projects__landing-cont__inner-box__bold-text">
            <h1>Portfolio</h1>
          </div>
          <div className="projects__landing-cont__inner-box__light-text">
            <span>We are proud and have pride in all of our works.</span>
            <br />
            <span>We believe our work speaks for itself, so take a look.</span>
          </div>
        </div>
      </div>
      {/* button */}
      <div className="projects__filter--cont" id="mobile-projects__filter__cont">
       {filteredButton("All")}
       {filteredButton("Residential")}
       {filteredButton("Commercial")}
       {filteredButton("Restaurant")}
      </div>
      {/* card */}
      <div className="projects__card-cont">
        <div className="projects__card-cont__innerbox" id="mobile-projects__card-cont__innerbox">
          {props.projects &&
            !props.filteredProjects &&
            props.projects.map((project) => cardblock(project))}
          {props.filteredProjects &&
            props.filteredProjects.map((project) => cardblock(project))}
          {/* {props.projects && !props.filteredProjects
            ? props.projects.map((project) => cardblock(project))
            : props.filteredProjects.map((project) => cardblock(project))} */}
        </div>
      </div>
      </div>
  );
};

export default withRouter(Projects);
