import React from "react";
import "./styles.scss";
import { Logo } from "../../svg-modules/index";
import { Link } from "react-router-dom";
import { useState } from "react";
import { addClientEmail } from "../../../util/api_utils/client_api_util";
import swal from "sweetalert";

const Footer = () => {
  const [email, setEmail] = useState("");

  function handleChange(e) {
    setEmail(e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    addClientEmail({ email })
      .then((res) =>
        swal({
          title: "Thank You for Subscribing Us!",
          text: `Email is ${res.data.email}`,
          icon: "success",
          button: "close",
        })
      )
      .catch((err) => {
        swal({
          title: `${err.response.data.errors}`,
          text: "Please try different Email.",
          button:"close",
          icon:"warning"
        })
      });
  }
  return (
    <div className="footer__wrapper" id="mobile-footer__wrapper">
      <div className="footer__left__container" id="mobile-footer__left__container">
        <div className="footer__logo__container">
          {Logo("white", "", "footer__logo")}
        </div>
        <div className="footer__email__container">
          <input
            value={email}
            name="email"
            onChange={handleChange}
            className="footer__email__text"
            placeholder="Email"
          ></input>
          <button className="footer__email__button" onClick={onSubmit}>
            Subscribe
          </button>
        </div>
        <div className="footer__text__container">
          <span>@2021 DESIGNED CREATED BY LTWIM</span>
        </div>
      </div>

      <div className="footer__right__container" id="mobile-footer__right__container">
        <div className="footer__links__container">
          <Link exact to="/about-us">
            About Us
          </Link>
          <Link exact to="/contact-us">
            Contact
          </Link>
        </div>
        <div className="footer__links__container">
          <Link exact to="/projects">
            Projects
          </Link>
          <Link exact to="/services">
            Service
          </Link>
        </div>
        <div className="footer__links__container">
          <Link exact to="/about-us">
            Privacy
          </Link>
          <Link exact to="/about-us">
            Careers
          </Link>
        </div>
        <div className="footer__links__container">
          <Link exact to="/about-us">
            Certificates
          </Link>
        </div>
        <div className="footer__hiddenEmail__container">
          <input
            value={email}
            name="email"
            onChange={handleChange}
            className="footer__email__text"
            placeholder="Email"
          ></input>
          <button className="footer__email__button">Subscribe</button>
        </div>
        <div className="footer__hiddenText__container">
          <span>@2021 DESIGNED CREATED BY LTWIM</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
