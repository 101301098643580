import { connect } from 'react-redux';
import Project from "./index"
import {getAllProjects, getProject} from "../../actions/entities/projects_actions"

const mapStateToProps = (state) => {
 return {
     projects: state.Entities.Projects.projects,
     project: state.Entities.Projects.project
 }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllProjects:() => dispatch(getAllProjects()),
        fetchProject:(id) => dispatch(getProject(id))
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Project)