import React from 'react'
import "./styles.scss"


const TextCard = (title) => {
    let content;

    switch(title) {
        case "Safety":
        content="Safety ALWAYS comes first in our work. Out team constantly committed in our actions to protect our environment and community."
        break;
        case "Our Values":
        content= "Trends change, and so should businesses. Remodel your business and give it a new refreshing look to keep up with the latest trends. We will work with you hand in hand to find the right design that will best fit your business and work in our expertise to give it that you new look you envisioned."
        break;
        case "Collaborative":
        content="Our Team work with integrity, fairness and honesty before, during and after project, by providing exceptional service and advise to earn and keep our clients' trust. We value our clients and aim to fullfill clients' needs in highest standard."
        break;
        case "Trusted" :
        content="Relationships in business are based on trust, that's why our team bring highest integrity and fairness to all our clients. Our team always stays honest and straightforward, takes responsibility for all the actions that we made."
        break;
        case "Focused":
        content="We treat clients' project like it's ours. Our Team stays professional and focused throughout the project with responsibility and commitment, to deliver finest quality on time."
        break;
        default : 
        return null
    }

    return(
        <div className={title === "Our Values" ? "textCard__text__header" : "textCard__text__subHeader"}>
            {title === "Our Values" ? <h2 className="textCard__text__title">{title}</h2> : <h4>{title}</h4>}
            {title !== "Our Values" && <span className="textCard__text__content">{content}</span>}
        </div>
    )
}

export default TextCard