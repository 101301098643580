import React from "react";

import { BrowserRouter } from "react-router-dom";
import AppContainer from "./App";


const Root = () => {


  return (
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
  )
};

export default Root;
