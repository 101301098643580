import React from "react";
import "./styles.scss"

const AboutUsCard = (title) => {
    let content;

    switch(title) {
        case "Teamwork":
            content= "Exceptional hand to hand coordination between members of company."
            break;
        case "Experience" :
            content="Exceptional experience in construction field.";
            break;
        case "Project Ownership":
            content="We treat your project as if our own.";
            break;
        default :
        return null;
    }

    return (
        <div className={title === "TeamWork" ? "midContent__textCard right" : "midContent__textCard left"}>
            <div className="midContent__textCard__title"><span className="textCard__title__span">{title}: </span></div>
            <div className="midContent__textCard__text"><span className="textCard__text__span">{content}</span></div>
        </div>
    )
}

export default AboutUsCard