import React, { useEffect, useState } from "react";
import "./styles.scss";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { fetchAllProjects } from "../../util/api_utils/projects_api_util";
const Project = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [project, setProject] = useState({});

  useEffect(() => {
    !props.projects && props.fetchAllProjects();
  }, []);
  const mappingPhotos = (photo) => {
    return (
      <div
        className="project__pic-2"
        id="mobile-project__pic-2"
        style={{
          // backgroundImage: `url(${photo})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <img src={`${photo}`} />
      </div>
    );
  };

  if (props.projects) {
    const { cost, size, year, pics, name, mainPic, description } =
      props.projects.find((prj) => prj._id === props.match.params.id);
    return (
      <div className="project-wrapper" overflow="hidden">
        <Helmet>
          <title>Our Work | New Cosmo Builders, INC.</title>
          <meta
            name="keywords"
            content="best residential remodeling company near me, best residential construction local company near me, best retail construction company near me, top construction local general contractor near me, best building contractors near me"
          />
          <meta
            name="description"
            content="We are proud and have pride in all of our works.
We believe our work speaks for itself, so take a look."
          />
        </Helmet>
        {/* lander banner */}
        <div
          className="project__landing-cont"
          id="mobile-project__landing-cont"
        >
          <div className="project__landing-cont__inner-box">
            <div className="project__landing-cont__inner-box__bold-text">
              {name}
            </div>
            <div className="project__landing-cont__inner-box__light-text">
              {cost && <div>Value: ${cost}</div>}
              {size && <div>Size: {size}</div>}
              {year && <div>Time frame: {year}</div>}
            </div>
          </div>
        </div>

        <div
          className="project__pic-1"
          id="mobile-project__pic-1"
          style={{
            // backgroundImage: `url(${mainPic})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        >
          <img src={`${mainPic}`} />
        </div>
        <div className="project__box-1-cont" id="mobile-project__box-1-cont">
          <div className="project__box-1-cont__right-cont">
            <div className="project__box-1-cont__titlebox-1">
              Project Description
            </div>
            <div className="project__box-1-cont__textbox-1">{description}</div>
          </div>
        </div>
        {pics.map((photo) => mappingPhotos(photo))}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(Project);
