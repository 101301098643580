import React, { useMemo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import LandingContainer from './landing/landing_container'
import AboutUs from './about-us'
import ContactUs from './contact-us'
import ProjectContainer from './project/project_container'
import ProjectsContainer from './projects/projects__container'
import Footer from "./modules/footer/index"
import Services from './services/index';
import {
  Navbar
} from'./modules'
import "../global_styles/_layout.scss"
const App = () => {


  return (
    <div className="app__wrapper">
      <Navbar />
      <div className="app__content__wrapper">
        <div className="app__content__wrapper__container">
      <Switch>
        <Route exact path="/" component={LandingContainer} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path="/projects" component={ProjectsContainer} />
        <Route path="/projects/:id" component={ProjectContainer}/>
        <Route exact path="/services" component={Services}/>
      </Switch>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default App;
