import { connect } from 'react-redux';
import Landing from "./index"
import {getAllProjects, getProject, fetchOnGoingProject} from "../../actions/entities/projects_actions"

const mapStateToProps = (state) => {
 return {
     projects: state.Entities.Projects.projects,
     onGoingProject : state.Entities.Projects.onGoingProjects
 }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllProjects:() => dispatch(getAllProjects()),
        fetchOnGoingProject : () => dispatch(fetchOnGoingProject())
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Landing)