import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss"

const ProjectCarousel = (props) => {
  let projects = props.projects.slice(0, 6);
  let mapRadioCheckBox = (i) => {
    let slide = `slide-${i + 1}`;
    return (
      <input
        type="radio"
        name="slides"
        value={slide}
        checked={props.slide === slide}
      />
    );
  };

  let mapCarouselSlide = (project) => {
      return (
        <li className="carousel__slide">
          <figure className="carousel__slide__figure">
            <div className="carousel__slide__image">
              <img src={project.slidePic ? project.slidePic : project} alt="" />
            </div>
            <figcaption className="carousel__slide__figCaption">
              <span className="carousel__slide__title">{project.name}</span>
              <span>{project.location}</span>
              <span>${project.cost}</span>
              <span>{project.size}</span>
              <Link to={`/projects/${project._id}`}>
                <button className="aboutus__button">Learn More</button>
              </Link>
            </figcaption>
          </figure>
        </li>
      );
  };

  let mapCarouselThumbNails = (project, index) => {
      return (
        <li
          onClick={(e) => {
            e.preventDefault();
            console.log(project)
            props.setCarouselSlide(`slide-${index + 1}`);
          }}
        >
          <label for={`slide-${index + 1}`}>
            <img src={project.slidePic ? project.slidePic : project} alt="" />
          </label>
        </li>
      );
  };
  return (
    <div className="projects__carousel__wrapper">
      <div className="projects__carousel__container">
        <div className="projects__carousel">
          {[...Array(6).keys()].map((idx) => mapRadioCheckBox(idx))}
          <ul className="carousel__slides">
            {projects.map(project => mapCarouselSlide(project))}
          </ul>
          <ul className="carousel__thumbnails">
            {projects.map((project,index) => mapCarouselThumbNails(project, index))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectCarousel;
