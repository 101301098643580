import { RECEIVE_PROJECTS_ERRORS } from "../../constants/index"

const projectErrors = [];

const ProjectErrorsReducer = (state = projectErrors, action) => {
    Object.freeze(state);
    switch(action.type) {
        case RECEIVE_PROJECTS_ERRORS:
            return {...state, projectErrors:action.error};
        default:
            return state;
    }     
}

export default ProjectErrorsReducer