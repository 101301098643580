import React ,{useEffect}from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import AboutUsCard from "../modules/aboutUs_card/index";
import { Helmet } from "react-helmet";

const Page = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="aboutUs-page-wrapper">
      <Helmet>
        <title>About Us | New Cosmo Builders, INC. </title>
        <meta name="keywords" content="local contractors near me, best construction companies near me, best home construction companies, best home remodeling companies, best construction companies near me"/>
        <meta name ="description" content="Over 30 years, New Comsmo Builders INC. has been serving customers with satisfaction."/>
      </Helmet>
      <div className="aboutUs__topContent__container" id="mobile-aboutUs__topContent__container">
        <div className="topContent__container--left">
          <div className="topContent__text__container">
            <div className="topContent__header--sm">
              <span>ABOUT US</span>
            </div>
            <div className="topContent__header--lg">
              <h3>New Cosmo Builders, Inc.</h3>
              <span>CONSTRUCTION COMPANY</span>
            </div>
            <div className="topContent__text">
              <span className="topContent__text__content">
                The built environment of your business can build business, but
                too often it becomes one designer's intrepretation of our
                practice. At New Cosmo Builders, we believe innovative and effective
                design and architecture begin with your own philosophy and a
                collaborative approach. We listen and learn, then consult with
                you from concept to construction, measuring at each phase the
                impact of decisions on you and your clients, the industry and
                community. With a combination of technology, creativity and
                human interaction we help you realize, not approximate, the
                space you envision.
              </span>
            </div>
          </div>
          <div className="text__botImg__container">
            <div className="botImg--left">
              <img src="https://images.unsplash.com/photo-1600645896997-3c00e14c0b23?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" />
            </div>
            <div className="botImg__hidden--left">
              <img src="https://images.unsplash.com/photo-1536895058696-a69b1c7ba34f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80" />
            </div>
            <div className="botImg--right">
              <img src="https://images.unsplash.com/photo-1592680998012-aaa9bbe43324?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" />
            </div>
            <div className="botImg__hidden--right">
              <img src="https://images.unsplash.com/photo-1547227002-491b20710ea5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1341&q=80" />
            </div>
          </div>
        </div>
        <div className="topContent__container--right">
          <div className="img__portrait__container" id="mobile-img__portrait__container">
            <img src="https://images.unsplash.com/photo-1536895058696-a69b1c7ba34f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80" />
          </div>
          <div className="img__landScape__container">
            <img src="https://images.unsplash.com/photo-1547227002-491b20710ea5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1341&q=80" />
          </div>
        </div>
      </div>
      <div className="aboutUs__midContent__container" id="mobile-aboutUs__midContent__container">
        <div className="midContent__container--left" id="mobile-midContent__container--left">
          <div className="text__header__container">
            <span>Why We Excel</span>
          </div>
          <div className="text__title__container">
            <h3>WHO WE ARE</h3>
          </div>
          <div className="text__content__container">
            <span className="text__content">
              We are our own critics. With a global perspective and breadth of
              experience, we demand a lot from each other and are invested in
              our projects. We treat the work we do for you in the public sector
              as though your business were ours.
            </span>
          </div>
        </div>
        <div className="midContent__container--right">
        {AboutUsCard("Experience")}
          {AboutUsCard("Teamwork")}
          {AboutUsCard("Project Ownership")}
        </div>
      </div>
      <div className="aboutUs__botContent__container" id="mobile-aboutUs__botContent__container">
        <div className="botContent__textCard--left">
          <div className="textCard__header--left">
            <h3>It is too easy to equate teamwork with success.</h3>
          </div>
          <div className="textCard__text--left">
            <span>
              At New Cosmo Builders, we go further and build it into our design and
              development process. We map out scope with you and eliminate
              potential pitfalls from the get-go, reducing time and saving
              dollars. We maintain consistency from start to finish so your
              ideas as visualized in our renderings are never compromised at
              final build.
            </span>
            <div className="textCard__button__container">
              <Link to="/contact-us">
                <button className="contact__button">Contact Us</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="botContent__textCard--right">
          <div className="textCard__header--right">
            <h3>WHAT WE DO</h3>
          </div>
          <div className="textCard__text--right">
            <span>
              New Cosmo Builders, Inc has established itself as an architecture,
              planning and interior design firm known for its inventive
              appraoach to the build environment. We carefully design to
              specific client needs, program, technical requirements and budget.
              Every assignment presents the opportunity to develop a unique and
              appropriate solution.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
