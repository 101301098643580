import React from "react";
import "./styles.scss";
import TextCard from "../modules/service_textCard/index";
import { Link } from "react-router-dom";
import {ServiceBanner} from "../modules/index.js"

const Services = () => {
  return (
    <div className="services-wrapper">
      <div className="services-container">
      {ServiceBanner("CONSTRUCTION")}
      {ServiceBanner("REMODELING")}
      {ServiceBanner("SPECIALITY")}
        <div className="services__bottom__container" id="mobile-services__bottom__container">
          <div className="bottom__img__container">
            <img src="https://static9.depositphotos.com/1004757/1193/i/600/depositphotos_11935432-stock-photo-industrial-construction-site.jpg" />
          </div>
          <div className="textCard__text__container">
            {TextCard("Our Values")}
            {TextCard("Collaborative")}
            {TextCard("Trusted")}
            {TextCard("Focused")}
            {TextCard("Safety")}
            <div className="textCard__button__container">
              <Link to="/contact-us">
                <button className="contact__button">Contact Us</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
