import React ,{useEffect}from "react";
import {ServiceSvg} from "../index.js"
import "./styles.scss"

const ServiceBanner = (type) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let subHeader;
  let description;
  let reverse;
  let svg;

  switch (type) {
    case "REMODELING":
      subHeader = "Advancing Your Business";
      description =
        "Our Preconstruction team helps owners plan their project by delivering accurate budgets, developing project execution plans and creating a communication plan that keeps all stakeholders involved and promotes the project in a positive manner.";
      reverse = "reversed";
      svg=["ResidentialRemodeling", "RetailRemodeling","RestaurantRemodeling", "CommercialRemodeling" ]
      break;
    case "CONSTRUCTION":
      subHeader = "Buildings from Scratch";
      description =
        "Our Preconstruction team helps owners plan their project by delivering accurate budgets, developing project execution plans and creating a communication plan that keeps all stakeholders involved and promotes the project in a positive manner.";
      svg=["ResidentialConstruction","RetailBuilding","RestaurantBuilding", "CommercialBuilding"]
      break;
    case "SPECIALITY":
      subHeader = "Our Company Offers";
      svg=["Foundation","WoodMetalFrame","StructuralRepair"]
      description =
        "Our Preconstruction team helps owners plan their project by delivering accurate budgets, developing project execution plans and creating a communication plan that keeps all stakeholders involved and promotes the project in a positive manner.";
      break;
    default:
      return null;
  }

  return (
    <div className="content-wrapper--center content-wrapper">
          <div className={`test-background test-background--${type}`}></div>
          <div className="test-content-wrapper">
            <div className=" test-content test-content--1">
              <div className="test-content--top">
                <div id = "mobile-services-content--wrapper" className={reverse ? "services-content--wrapper reversed":"services-content--wrapper normal"}>
                  <div className="service-content--container-1">
                    <h1>{type ==="SPECIALITY" ? "OTHER SERVICES" : type}</h1>
                    <h4>{subHeader}</h4>
                    <span>
                   {description}
                    </span>
                  </div>
                  <div className="service-content--container-2">
                    {ServiceSvg(`${svg[0]}`)}
                    {ServiceSvg(`${svg[1]}`)}
                    {ServiceSvg(`${svg[2]}`)}
                    {type !== "SPECIALITY" && ServiceSvg(`${svg[3]}`)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default ServiceBanner;
