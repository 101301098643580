import React from "react";
import "./styles.scss";
import {
  Commercial,
  Retail,
  Restaurant,
  Residential,
  BuildingTwo,
  RetailTwo,
  RestaurantTwo,
  CommercialTwo,
  Foundation,
  WoodMetalFrame,
  StructuralRepair
} from "../../svg-modules/index.js";

const ServiceSvg = (type) => {
  let title;
  let svg;

  switch (type) {
    case "ResidentialConstruction":
      title = "Residential Construction";
      svg = Residential("", "service__card__svg", "service-card-svg-outer");
      break;
    case "ResidentialRemodeling":
      title = "Residential Remodeling";
      svg = BuildingTwo("", "service__card__svg", "service-card-svg-outer");
      break;
    case "RetailBuilding":
      title = "Retail Construction";
      svg = Retail("", "service-card-svg", "service-card-svg-outer");
      break;
    case "RetailRemodeling":
      title = "Retail Remodeling";
      svg = RetailTwo("", "service-card-svg", "service-card-svg-outer");
      break;
    case "RestaurantBuilding":
      title = "Restaurant Construction";
      svg = Restaurant("", "service-card-svg", "service-card-svg-outer");
      break;
    case "RestaurantRemodeling":
      title = "Restaurant Remodeling";
      svg = RestaurantTwo("", "service-card-svg", "service-card-svg-outer");
      break;
    case "CommercialBuilding":
      title = "Commercial Construction";
      svg = Commercial("", "service-card-svg", "service-card-svg-outer");
      break;
    case "CommercialRemodeling":
      title = "Commercial Remodeling";
      svg = CommercialTwo("", "service-card-svg", "service-card-svg-outer");
      break;
    case "Foundation":
      title = "Foundation";
      svg = Foundation("", "service-card-svg", "service-card-svg-outer");
      break;
    case "WoodMetalFrame":
      title = "Wood & Metal Frame";
      svg = WoodMetalFrame("", "service-card-svg", "service-card-svg-outer");
      break;
    case "StructuralRepair":
      title = "Retrofitting";
      svg = StructuralRepair("", "service-card-svg", "service-card-svg-outer");
      break;
    default:
      return null;
  }

  return (
    <div className="svg__container">
      <div className="svg__image">{svg}</div>
      <div className="svg__title">{title}</div>
    </div>
  );
};

export default ServiceSvg;
