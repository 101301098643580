import {
  RECEIVE_ALL_PROJECTS,
  RECEIVE_ON_GOING_PROJECTS,
  RECEIVE_PROJECT,
  RECEIVE_PROJECTS_ERRORS,
  RECEIVE_FILTERED_PROJECTS
} from "../../constants/index";

import * as ProjectAPI from '../../util/api_utils/projects_api_util'

const receiveOnGoingProjects = (payload) => ({
  type:RECEIVE_ON_GOING_PROJECTS,
  payload
})

export const receiveAllProjects = (projects) => ({
  type: RECEIVE_ALL_PROJECTS,
  projects: projects,
});

export const receiveProject = (project) => ({
  type: RECEIVE_PROJECT,
  project: project,
});

export const receiveProjectError = (err) => ({
  type: RECEIVE_PROJECTS_ERRORS,
  error: err,
});

export const receiveFilteredProjects = (projects) => ({
  type:RECEIVE_FILTERED_PROJECTS,
  filteredProjects: projects
})
export const getAllProjects = () => async (dispatch) => {
  try {
    ProjectAPI.fetchAllProjects().then((res) => {
      dispatch(receiveAllProjects(res.data.allProjects));
      return res.data.allProjects
    })
  } catch (err) {
    dispatch(receiveProjectError(err));
  }
};

export const getProject = (id) => async (dispatch) => {
  try {
    const project = await ProjectAPI.fetchProject(id);
    dispatch(receiveProject(project.data.project));
    return project.data.project
  } catch (err) {
    dispatch(receiveProjectError(err));
  }
};

export const fetchOnGoingProject = () => dispatch => {
  return ProjectAPI.fetchOnGoingProject().then(res => dispatch(receiveOnGoingProjects(res.data)))
}
